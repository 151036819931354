<template>
	<TransitionRoot appear :show="modalStore.showStocktakePostModal" as="template">
		<Dialog as="div" @close="modalStore.closeStocktakePostModal()" class="relative z-10">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-black/25" />
			</TransitionChild>

			<div class="fixed inset-0 overflow-y-auto">
				<div class="flex min-h-full items-center justify-center p-4 text-center">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
						>
							<DialogTitle
								as="h3"
								class="text-lg font-medium leading-6 text-gray-900 items-center justify-center flex flex-col"
							>
								<InformationCircleIcon class="h-16 w-16 text-blue-700" />
								<span>Are you sure you want to continue?</span>
							</DialogTitle>

							<hr />

							<div class="flex items-center mb-2 mt-2">
								<InformationCircleIcon class="h-5 w-5 mr-2 text-gray-500" />
								<span class="text-sm text-gray-600">Select sheets to sync:</span>
							</div>
							<ul class="space-y-1">
								<li v-for="sheet in sheets" :key="sheet.id">
									<label :for="`sheet-${sheet.id}`">
										<input
											type="checkbox"
											:id="`sheet-${sheet.id}`"
											v-model="selectedSheetData"
											:value="sheet.id"
										/>
										<span class="ml-2 text-sm text-gray-700">{{ sheet.file_name }}</span>
									</label>

									<div class="ml-4 text-sm text-gray-600 flex flex-col items-end">
										<span
											>Total:
											<span class="font-bold">{{
												scannedBreakdown.find((b) => b.sheetId === sheet.id)?.total || 0
											}}</span></span
										>
										<span class="text-green-600">
											Scanned:
											<span class="font-bold">{{
												scannedBreakdown.find((b) => b.sheetId === sheet.id)?.scanned || 0
											}}</span>
										</span>
										<span class="text-red-600">
											Not Scanned:
											<span class="font-bold">{{
												scannedBreakdown.find((b) => b.sheetId === sheet.id)
													?.notScanned || 0
											}}</span>
										</span>
									</div>

                  <!-- Add HR only if it's not the last item -->
                  <hr v-if="index !== sheets.length - 1" class="mt-2 border-gray-300" />
								</li>
							</ul>

							<hr />

							<div class="mt-4 flex justify-between">
								<button
									type="button"
									class="inline-flex justify-center rounded-md border border-transparent text-base font-medium px-4 py-2 bg-green-500 hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
									@click="syncData(selectedSheetData)"
								>
									Confirm
								</button>

								<button
									type="button"
									class="inline-flex justify-center rounded-md border border-transparent text-base font-medium px-4 py-2 bg-red-500 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
									@click="modalStore.showStocktakePostModal = false"
								>
									Cancel
								</button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import { ref, computed } from "vue";
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from "@headlessui/vue";

import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { useStockTakeStore } from "@/stores/stockTakeStore";
import router from "@/router";
import { useModalStore } from "@/stores/modalStore";

const modalStore = useModalStore();

const stockTakeStore = useStockTakeStore();
const sheets = computed(() => stockTakeStore.stockTakeSheets);
const selectedSheetData = ref([]); // Array to store selected sheet IDs

const filteredSheets = computed(() => {
	return sheets.value.filter((sheet) => {
		return selectedSheetData.value.includes(sheet.id);
	});
});

const scannedBreakdown = computed(() => {
	return sheets.value.map((sheet) => {
		const sheetProducts = stockTakeStore.products.filter(
			(product) => product.stock_take_sheet_id === sheet.id
		);

		const scannedProducts = sheetProducts.filter((product) =>
			stockTakeStore.stockTakeHistory.some((history) => history.productId === product.id)
		);

		return {
			sheetId: sheet.id,
			total: sheetProducts.length,
			scanned: scannedProducts.length,
			notScanned: sheetProducts.length - scannedProducts.length,
		};
	});
});


const logActivity = (action, details) => {
	stockTakeStore.logActivity(action, details);
};


const syncData = (selectedSheetData) => {
	document.getElementById("loader-wheel").classList.remove("hidden");

  logActivity("Confirmed clicked to sync the following sheet data", {
		selectedSheetData: selectedSheetData,
	});

	try {
		stockTakeStore.syncData(filteredSheets.value);

		modalStore.showStocktakePostModal = false;
		document.getElementById("loader-wheel").classList.add("hidden");

		showSuccessToast();

		console.log("confirm sheets to sync clicked");
		console.log("selectedSheetIds", selectedSheetData);
		console.log("filteredSheets", filteredSheets.value);

		router.push({ name: "Sheets" });
	} catch (error) {
		console.error("Error syncing data:", error);
		document.getElementById("loader-wheel").classList.add("hidden");
	} finally {
		document.getElementById("loader-wheel").classList.add("hidden");
	}

	closeModal();
};

function closeModal() {
	modalStore.showStocktakePostModal = false;
}

const showSuccessToast = () => {
	document.getElementById("syncToast").classList.remove("hidden");
	// Hide the toast after 5 seconds (5000ms)
	// you can set a shorter/longer time by replacing "5000" with another number
	setTimeout(function () {
		document.getElementById("syncToast").classList.add("hidden");
	}, 5000);
};
</script>
